<template>
  <div>
    <q-table
      flat
      bordered
      :data="invoicesPaymentsStep === null ? [] : invoicesPaymentsStep"
      :columns="columns"
      row-key="id"
      :pagination.sync="pagination"
      no-data-label="Não existem faturas pendentes de liberação"
      selection="multiple"
      :selected.sync="itemSelect"
    >
      <template v-slot:header-selection>
        <span>Selecionar</span>
      </template>
      <template v-slot:top>
        <q-toolbar>
          <q-btn
            color="positive"
            label="Aprovar faturas selecionadas"
            no-caps
            unelevated
            class="q-px-md"
            @click="onActionInvoices(itemSelect)"
            :disabled="itemSelect.length == 0"
          >
            <q-tooltip> Aprovar Faturas </q-tooltip>
          </q-btn>
        </q-toolbar>
      </template>

      <template v-slot:body-cell-invoice="props">
        <q-td :props="props">
          <q-btn
            flat
            color="primary"
            @click="showInvoice(props.row.outbound_invoice_uuid)"
            icon="fas fa-file-invoice-dollar"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-id_invoices_payments="props">
        <q-td :props="props">
          <div class="ellipsis" style="max-width: 100px" v-if="props.row.invoice_payments_ids.split('').length > 12">
            {{ props.row.invoice_payments_ids.split(',').join(', ') }}
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              {{ props.row.invoice_payments_ids.split(',').join(', ') }}
            </q-tooltip>
          </div>
          <div v-else>
            {{ props.row.invoice_payments_ids.split(',').join(', ') }}
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-date="props">
        <q-td :props="props">
          <q-chip dense v-if="props.row.payment_day" color="primary" class="text-white">
            {{ $moment(props.row.payment_day).format('DD-MM-YYYY') }}
          </q-chip>
        </q-td>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <q-btn color="negative" unelevated flat icon="mdi-cancel" @click="onActionInvoices(props.row)" disable>
            <!-- :disable="itemSelect != 0" -->
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Reprovar </q-tooltip>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:bottom>
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
            <div class="row items-center">
                <span class="q-mr-md">Registros por página:</span>
                    <q-select
                    v-model="pagination.rowsPerPage"
                    :options="[10, 20, 50, 100]"
                    dense
                    @input="onRowsPerPageChange"
                    style="max-width: 60px; padding-left: 8px; padding-right: 8px"
                    />
                <span>Total: {{ reqPagination.total }}</span>
            </div>
          <q-space />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
    <q-dialog v-model="dialog" persistent>
      <q-card style="min-width: 500px">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>
            {{ itemSelect.length != 0 ? 'Informe a data de pagamento' : 'Motivo para a reprovação' }}
          </q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="onCloseDialog" />
        </q-toolbar>
        <q-card-section class="row q-col-gutter-md" v-if="itemSelect.length != 0">
          <div class="col-12">
            <date-input
              :v-model="formModal.date"
              @update="(value) => (formModal.date = value)"
              outlined
              label="Data do pagamento"
            />
          </div>
        </q-card-section>
        <q-card-section class="row q-col-gutter-md" v-else>
          <div class="col-12">
            <q-select
              v-model="formModal.motive"
              :options="['Outros', 'Dados bancarios incompletos']"
              label="Motivo"
              outlined
              @input="setDefaultResponses"
            />
          </div>
          <div class="col-12">
            <q-input v-model="formModal.subject" type="text" label="Assunto" outlined />
          </div>
          <div class="col-12">
            <q-input v-model="formModal.message" type="textarea" label="Mensagem do motivo" outlined />
          </div>
        </q-card-section>
        <q-separator spaced />
        <q-card-actions align="right">
          <q-btn outline color="negative" label="Cancelar" unelevated no-caps class="q-px-md" @click="onCloseDialog" />
          <q-btn
            v-if="itemSelect.length != 0"
            unelevated
            no-caps
            class="q-px-md"
            label="Confirmar Agendamento"
            color="primary"
            @click="onAlterPayment"
            :disable="formModal.date == '' || formModal.date == null"
          />
          <q-btn
            v-else
            unelevated
            no-caps
            class="q-px-md"
            label="Enviar motivo"
            color="primary"
            @click="onSubmitMotive"
            :disable="formModal.subject == '' || formModal.subject == null"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'ListApproveInvoices',
  components: { DateInput },
  mixins: [FinancialMixin],
  created() {
      this.ActionSetFilterInvoicesPaymentsStep('states=%5B12%5D');
      this.setup();
      this.pagination.rowsPerPage = parseInt(localStorage.getItem('rowsPerPage.ApproveInvoices')) || 10;
      // this.getInvoicesPaymentsAffiliate(`%26page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
  },
  data() {
    return {
      dialog: false,
      itemSelect: [],
      itemReprove: null,
      formModal: {
        subject: null,
        message: null,
        motive: 'Outros',
        date: null
      },
      params: {
        payout: null,
        paymentDay: null
      },
      columns: [
        {
          name: 'affilate_id',
          required: true,
          label: 'ID Afiliado',
          align: 'left',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'affilate',
          required: true,
          label: 'Afiliado',
          align: 'left',
          field: (row) => row.affiliate ? row.affiliate.name : 'N/A',
          sortable: true
        },
        {
          name: 'total_commissions',
          required: true,
          label: 'Valor da Fatura',
          align: 'left',
          field: 'total_commissions',
          format: (total_commissions) => `R$ ${total_commissions}`,
          sortable: true
        },
        {
          name: 'invoice',
          required: true,
          label: 'Fatura',
          align: 'left',
          field: 'invoice',
          sortable: true
        },
        {
          name: 'id_invoices_payments',
          required: true,
          label: 'No. da Fatura',
          align: 'left',
          field: 'invoice_payments_ids',
          sortable: true
        },
        {
          name: 'date',
          required: true,
          label: 'Data de Pagamento',
          align: 'left',
          field: 'payment_day',
          sortable: true
        },

        {
          name: 'id_states',
          required: true,
          label: 'Status',
          align: 'left',
          field: 'id_states',
          format: (state) => this.formatStates(state),
          sortable: true
        },
        {
          name: 'actions',
          required: true,
          label: 'Ações',
          align: 'center'
        }
      ],
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  methods: {
    setup() {
      this.getInvoicesPaymentsAffiliate();
      for (let i in this.formModal) this.formModal[i] = null;
      this.formModal.motive = 'Outros';
      this.itemReprove = null;
      this.itemSelect = [];
    },
    setDefaultResponses(reason) {
      if (reason === 'Dados bancarios incompletos') {
        this.formModal.subject = 'Dados bancários Incompletos';
        this.formModal.message =
          'Caro Afiliado, \n' +
          '\n' +
          'Por favor, verificar seus dados bancários. \n' +
          'Precisamos que todos os campos na área de informações para pagamento estejam preenchidos corretamente. \n' +
          '\n' +
          'Se precisar de ajuda, mande um e-mail para contato@afil.io. \n' +
          'Obrigado.';
      } else {
        this.formModal.subject = null;
        this.formModal.message = null;
      }
    },

    onActionInvoices(data) {
      this.dialog = true;
      if (data.isArray) this.itemSelect = data;
      else this.itemReprove = data;
    },

    onCloseDialog() {
      this.dialog = false;
      this.itemSelect = [];
      this.itemReprove = null;
    },

    async onSubmitMotive() {
      this.onLoading(true);

      try {
        this.dialog = false;

        let formUpdate = {};

        formUpdate.invoices_payments_id = this.itemReprove.invoices_payments_id;
        formUpdate.disapproved_reason = this.formModal.motive;
        formUpdate.disapproved_title = this.formModal.title;
        formUpdate.disapproved_message = this.formModal.message;
        formUpdate.payment_day = null;
        formUpdate.id_states = 2;
        formUpdate.payment_status = 0;

        let request = {
          app: 'finance',
          path: '/api/invoices_payments/',
          params: {
            data: {
              invoices: JSON.stringify([formUpdate])
            }
          }
        };

        const { status } = await this.$http.put('v2/redirect', request);

        if (status === 200) {
          this.successNotify('Fatura atualizada com sucesso');

          this.setup();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onAlterPayment() {
      this.onLoading(true);

      try {
        this.dialog = false;

        let formUpdate = [];

        for (let i in this.itemSelect) {
          let invoicePayments = this.itemSelect[i].invoice_payments_ids.split(',');

          for (let j in invoicePayments) {
            let form = {};

            form.invoices_payments_id = invoicePayments[j];
            form.disapproved_reason = null;
            form.disapproved_title = null;
            form.disapproved_message = null;
            form.id_states = 4;
            form.payment_status = 2;
            form.payment_day = this.formModal.date;

            formUpdate.push(form);
          }
        }

        let request = {
          app: 'finance',
          path: '/api/invoices_payments/',
          params: {
            data: {
              invoices: JSON.stringify(formUpdate)
            }
          }
        };
        const { status } = await this.$http.put('v2/redirect', request);

        if (status === 200) {
          this.successNotify('Fatura atualizada com sucesso');

          this.setup();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    formatPaymentDate(date) {
      console.log('date', date);
      if (date === null) {
        return 'Sem data definida';
      } else {
        return this.$moment(date).format('L');
      }
    },

    async onUpdateRequest() {
      try {
        let UpdateParams = this.invoicesPaymentsStep.data.map(function (item) {
          let formattedValues = {};

          formattedValues.invoices_payments_id = item.invoices_payments_id;
          formattedValues.disapproved_reason = item.disapproved_reason;
          formattedValues.disapproved_title = item.disapproved_title;
          formattedValues.disapproved_message = item.disapproved_message;
          formattedValues.payment_day = this.$moment(this.stringToDate(item.payment_day, 'dd/MM/yyyy', '/')).format(
            'YYYY-MM-DD'
          );
          formattedValues.id_states = item.id_states;
          formattedValues.payment_status = item.payment_status;

          return formattedValues;
        });

        let request = {
          app: 'finance',
          path: '/api/invoices_payments/',
          params: {
            data: {
              invoices: JSON.stringify(UpdateParams)
            }
          }
        };

        const { status } = await this.$http.put('v2/redirect', this.parseQs(request));
        if (status === 200) {
          this.successNotify('Canais incluídos com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    showInvoice(invoiceHash) {
      let route = this.$router.resolve({ path: `/invoice/${invoiceHash}` });
      window.open(route.href, '_blank');
    },

    formatStates(state) {
      state = state.toString();
      switch (state) {
        case '1':
          return 'Aprovado'; //Aguardando liberação

        case '2':
          return 'Liberado'; //Liberado para solicitação

        case '3':
          return 'Solicitado'; //Aguardando agendamento

        case '4':
          return 'Agendado';

        case '5':
          return 'Pago';

        case '11':
          return 'Recusado';

        case '12':
          return 'Pré-Aprovado';

        default:
          return '-';
      }
    },
    onRowsPerPageChange(value) {
      this.pagination.rowsPerPage = value;
      localStorage.setItem('rowsPerPage.ApproveInvoices', value);
      this.currentPage = 1;
      this.getInvoicesPaymentsAffiliate(`%26page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
    }
  },

  watch: {
    itemsPerPage(newVal, oldVal) {
        if (newVal !== oldVal) {
        this.pagination.page = 1;
        this.getInvoicesPaymentsAffiliate(`%26page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
        }
    },
    currentPage(newVal, oldVal) {
        if (newVal !== oldVal) {
        this.getInvoicesPaymentsAffiliate(`%26page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
        }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  }
};
</script>
